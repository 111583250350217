<template>
  <div class="merchant-form">
    <div class="padding">
      <Form :model="form" :rules="validationRules" ref="form">
        <FormItem label="行业类别" prop="category">
          <Select dict="storeCategory" v-model="form.category" :deletable="false"/>
        </FormItem>
        <FormItem label="店铺名称" prop="merchantName">
          <input type="text" v-model="form.merchantName">
        </FormItem>
        <FormItem label="店铺地址" prop="address">
          <input type="text" v-model="form.address">
        </FormItem>
        <FormItem label="老板姓名" prop="bossName">
          <input type="text" v-model="form.bossName">
        </FormItem>
        <FormItem label="电话" prop="mobile">
          <input type="text" v-model="form.mobile">
        </FormItem>
      </Form>
    </div>
    <div class="h-modal-footer">
      <Button :loading="isLoading" @click="$emit('close')" icon="fa fa-close">取消</Button>
      <Button :loading="isLoading" @click="save()" color="primary" icon="fa fa-save">保存</Button>
    </div>
  </div>
</template>

<script>
import Merchant from "@/api/Merchant";

export default {
  name: "MerchantForm",
  props: {
    entity: Object,
  },
  data() {
    return {
      isLoading: false,
      form: {
        id: null,
        merchantName: '',
        address: '',
        category: '',
        mobile: '',
        bossName: ''
      },
      validationRules: {
        required: ['merchantName', 'address', 'category', 'mobile', 'bossName'],
        mobile: ['mobile']
      }
    }
  },
  methods: {
    save() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.isLoading = true;
        Merchant.save(this.form).then(({data}) => {
          this.$emit('success');
          this.$emit('close');
        }).finally(() => {
          this.isLoading = false;
        });
      }
    }
  },
  created() {
    Utils.copyObj(this.form, this.entity);
  }
}
</script>

<style scoped>
.merchant-form {
  width: 40vw;
}
</style>